import { servicesList } from "data";
function Services() {
  return (
    <section className="featured-services">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="titles">
              <span className="main-title">Digital Services we offer</span>
              <h3>
                We help to create strategies, <span>design & development.</span>{" "}
              </h3>
            </div>
          </div>
          <div className="featured-inner digital-services">
            {servicesList.slice(0, 3).map((item, index) => (
              <div key={index} className="featured-grid">
                <div className="digital-services-img">{item.icon}</div>
                <figcaption>
                  <h4>{item.title}</h4>
                  <ul>
                    {item.details.map((x, i) => (
                      <li key={i}>{x.text}</li>
                    ))}
                  </ul>
                </figcaption>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
