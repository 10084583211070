export default Object.freeze({
  HOME: "/",
  ABOUT: "/",
  SERVICES: "/services",
  CONTACT: "/contact",
  GET_QUOTE: "/get-quote",
  INSTAGRAM: "https://www.instagram.com/zeezsoft",
  LINKEDIN: "https://www.linkedin.com/company/zeez-soft",
  TWITTER: "https://twitter.com/SoftZeez",
  FACEBOOK: "https://www.facebook.com/profile.php?id=100085795506101",
});
