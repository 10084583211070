import {
  BsLaptopFill,
  BsFillPhoneFill,
  BsBrushFill,
  BsMegaphoneFill,
  BsDropletFill,
} from "react-icons/bs";
import { FaRobot } from "react-icons/fa";

export const servicesList = [
  {
    title: "Web Development",
    icon: <BsLaptopFill size={24} />,
    details: [
      {
        text: "- Frontend Development",
      },
      {
        text: "- Backend Development",
      },
      {
        text: "- MERN Stack Development",
      },
      {
        text: "- Full Stack Development",
      },
    ],
  },
  {
    title: "Mobile App Development",
    icon: <BsFillPhoneFill size={24} />,
    details: [
      {
        text: "- iOS / Android Apps",
      },
      {
        text: "- React Native Apps",
      },
      {
        text: "- Flutter Apps",
      },
      {
        text: "- IOT Apps",
      },
    ],
  },
  {
    title: "AI & Data Analytics",
    icon: <FaRobot size={24} />,
    details: [
      {
        text: "- ChatGPT API Integration",
      },
      {
        text: "- Artificial Intelligence",
      },
      {
        text: "- Data Analytics",
      },
      {
        text: "- Machine Learning",
      },
    ],
  },
  {
    title: "UI/UX Design",
    icon: <BsBrushFill size={24} />,
    details: [
      {
        text: "- Research",
      },
      {
        text: "- Prototyping",
      },
      {
        text: "- Interface Design",
      },
      {
        text: "- Interaction Design",
      },
    ],
  },
  {
    title: "Digital Marketing",
    icon: <BsMegaphoneFill size={24} />,
    details: [
      {
        text: "- Search Engine Optimization",
      },
      {
        text: "- Social Media Marketing",
      },
      {
        text: "- Video Making",
      },
      {
        text: "- Product Branding",
      },
    ],
  },
  {
    title: "IT Consultancy",
    icon: <BsDropletFill size={24} />,
    details: [
      {
        text: "- Supply Chain",
      },
      {
        text: "- Devops Solutions",
      },
      {
        text: "- Amazon Expert, Walmart, eBay ",
      },
      {
        text: "- Shopify, Facebook drop shipping",
      },
    ],
  },
];

export const interests = [
  {
    label: "Web development",
  },
  {
    label: "Web design",
  },
  {
    label: "App development",
  },
  {
    label: "App design",
  },
  {
    label: "Frontend development",
  },
  {
    label: "Backend development",
  },
  {
    label: "Site from scratch",
  },
  {
    label: "App from scratch",
  },
  {
    label: "React Js",
  },
  {
    label: "React Native",
  },
  {
    label: "Node Js",
  },
  {
    label: "Next Js",
  },
  {
    label: "TypeScript",
  },
  {
    label: "JavaScript",
  },
  {
    label: "Django",
  },
  {
    label: "Flask",
  },
  {
    label: "Python",
  },
  {
    label: "Chat GPT",
  },
  {
    label: "OPEN AI",
  },
  {
    label: "AWS",
  },
  {
    label: "Aws Amplify",
  },
  {
    label: "Firebase",
  },
  {
    label: "Php",
  },
  {
    label: "Php Laravel",
  },
  {
    label: "Flutter",
  },
  {
    label: "Vue",
  },
  {
    label: "Web 3",
  },
  {
    label: "GraphQL",
  },
  {
    label: "SASS/SCSS/CSS3",
  },
  {
    label: "Tailwind CSS",
  },
  {
    label: "Bootstrap",
  },
  {
    label: "Material UI",
  },
  {
    label: "UX/UI",
  },
  {
    label: "Figma",
  },
  {
    label: "Branding",
  },
  {
    label: "Illustrations",
  },
];

export const companiesLogos = [
  {
    label: "SteadyStudios",
    image: "images/clients/steady-studios-logo.avif",
  },
  {
    label: "Tour",
    image: "images/clients/tour.svg",
  },
  {
    label: "SylphAI",
    image: "images/clients/sylphai.svg",
  },
  {
    label: "Kwik",
    image: "images/clients/kwik-w.svg",
  },
  {
    label: "Notice",
    image: "images/clients/notice-w.svg",
  },
  // {
  //   label: "Taskade",
  //   image: "images/clients/taskade-w.svg",
  // },
  {
    label: "Unmarshal",
    image: "images/clients/Unmarshal-w.svg",
  },
  // {
  //   label: "Wellow",
  //   image: "images/clients/wellow-w.svg",
  // },
];
