import React, { useEffect } from "react";
import WOW from "wowjs";
import ReactGA from "react-ga";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./assets/css/animate.min.css";
import "./assets/css/fancybox.min.css";
import "./assets/css/swiper.min.css";
import "swiper/css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";

// View
import Splash from "./views/splash";
import Home from "./views/home";
import Contact from "views/contact";
import Services from "views/services";
import Quote from "views/quote";

/* React Toastify */
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import routes from "routes";

ReactGA.initialize("UA-270017879-1");
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Splash />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path={routes.CONTACT} element={<Contact />}></Route>
          <Route path={routes.SERVICES} element={<Services />}></Route>
          <Route path={routes.GET_QUOTE} element={<Quote />}></Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer
        autoClose={5000}
        position="top-center"
        className="toast-container"
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}

export default App;
