import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import routes from "routes";

function LeftSidebar() {
  const [showLogo, setShowLogo] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (document.documentElement.scrollTop > 300) {
        setShowLogo(true);
      } else {
        setShowLogo(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("touchmove", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("touchmove", handleScroll);
    };
  }, []);

  return (
    <aside className="left-side">
      <div className={`logo ${showLogo ? "sticky" : ""}`}>
        <img src="images/logo-icon.png" alt="Image" />
      </div>
      <ul>
        <li>
          <Link target="_blank" to={routes.FACEBOOK} className="pointer">
            FACEBOOK
          </Link>
        </li>
        <li>
          <Link target="_blank" to={routes.INSTAGRAM} className="pointer">
            INSTAGRAM
          </Link>
        </li>
        <li>
          <Link to={routes.TWITTER} target="_blank" className="pointer">
            TWITTER
          </Link>
        </li>
        <li>
          <Link to={routes.LINKEDIN} target="_blank" className="pointer">
            LINKEDIN
          </Link>
        </li>
      </ul>
      <a href="#top" className="gotop">
        <img src="images/logo-icon.png" alt="Image" />
      </a>
    </aside>
  );
}

export default LeftSidebar;
