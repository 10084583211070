import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import { companiesLogos } from "data";

function Logos() {
  return (
    <Swiper
      slidesPerView={5}
      loop={true}
      spaceBetween={40}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      modules={[Autoplay, Pagination, Navigation]}
      breakpoints={{
        280: {
          slidesPerView: 3,
        },
        992: {
          slidesPerView: 4,
        },
        1200: {
          slidesPerView: 5,
        },
      }}
    >
      {companiesLogos.map((item, index) => (
        <SwiperSlide key={index}>
          <div className={`item ${item.label}`}>
            <img src={item.image} alt={item.label} />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default Logos;
