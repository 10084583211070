import React from "react";

function MenuIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      fill="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 6a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1Z"></path>
      <path d="M2 12.031a1 1 0 0 1 1-1h18a1 1 0 0 1 0 2H3a1 1 0 0 1-1-1Z"></path>
      <path d="M3 17.063a1 1 0 1 0 0 2h18a1 1 0 0 0 0-2H3Z"></path>
    </svg>
  );
}

export default MenuIcon;
