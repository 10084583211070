import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Layout from "components/Layout";
import { isValidEmail } from "utils/common";
import { interests } from "data";

function GetQuote() {
  const [fieldValues, setFieldValues] = useState({
    interested: [],
    name: "",
    email: "",
    subject: "",
    budget: 0,
  });

  const handleInterestChange = (e) => {
    setFieldValues({
      ...fieldValues,
      interested: [...fieldValues.interested, e.target.value],
    });
  };

  const handleInputChange = (e) => {
    setFieldValues({
      ...fieldValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    if (!fieldValues.name) {
      toast("Your name is required", {
        type: "error",
      });
    } else if (!fieldValues.email) {
      toast("Your email is required", {
        type: "error",
      });
    } else if (!isValidEmail(fieldValues.email)) {
      toast("Enter valid email", {
        type: "error",
      });
    } else {
      try {
        const response = await axios.post(
          "https://salmon-codfish-cuff.cyclic.app/api/contactUs",
          fieldValues
        );
        // https://presale-backend-nine.vercel.app/api/contactUs
        if (response.data?.status) {
          toast("Your email send!", {
            type: "success",
          });
          window.location.reload();
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <Layout>
      <div className="page-title mb-0">
        <div className="container">
          <h2 className="data-slide-up">Quotation</h2>
          <h4 className="data-slide-up">
            We are excited to take on your new project and help you build
            something amazing!
          </h4>
        </div>
      </div>
      <section className="contact pt-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 wow fadeInUp">
              <div className="contact-form">
                <form id="contact" name="contact" method="post">
                  <div className="form-group form-group2">
                    <p>I'm interested in...</p>
                    {interests.map((item, index) => (
                      <label key={index}>
                        <input
                          type="checkbox"
                          name="interested"
                          value={item.label}
                          id={item.label}
                          onChange={handleInterestChange}
                        />
                        <i>
                          <b className="withoutHover">{item.label}</b>{" "}
                          <b className="hover">{item.label}</b>
                        </i>
                      </label>
                    ))}
                  </div>
                  <div className="form-group cb-input_light">
                    <input
                      type="text"
                      placeholder="Your Name"
                      name="name"
                      id="name"
                      autocomplete="off"
                      required
                      value={fieldValues.name}
                      onChange={handleInputChange}
                    />
                    <div className="cb-input_light-line"></div>
                  </div>

                  <div className="form-group cb-input_light">
                    <input
                      type="text"
                      placeholder="Your Email"
                      name="email"
                      id="email"
                      autocomplete="off"
                      required
                      value={fieldValues.email}
                      onChange={handleInputChange}
                    />
                    <div className="cb-input_light-line"></div>
                  </div>

                  <div className="form-group cb-input_light">
                    <input
                      type="text"
                      placeholder="Tell us about your project"
                      name="subject"
                      id="subject"
                      autocomplete="off"
                      required
                      value={fieldValues.subject}
                      onChange={handleInputChange}
                    />
                    <div className="cb-input_light-line"></div>
                  </div>
                  <div className="form-group form-group2 budget-checkbox">
                    <p>Project budget (USD)</p>
                    <label>
                      <input
                        type="checkbox"
                        name="budget"
                        value="2-5k"
                        id="budget1"
                        onChange={(e) => {
                          setFieldValues({
                            ...fieldValues,
                            budget: e.target.value,
                          });
                        }}
                      />
                      <i>
                        <b className="withoutHover">$2-5k</b>{" "}
                        <b className="hover">$2-5k</b>
                      </i>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        name="budget"
                        value="5-10k"
                        id="budget2"
                      />
                      <i>
                        <b className="withoutHover">$5-10k</b>{" "}
                        <b className="hover">$5-10k</b>
                      </i>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        name="budget"
                        value="10-20k"
                        id="budget3"
                      />
                      <i>
                        <b className="withoutHover">$10-20k</b>{" "}
                        <b className="hover">$10-20k</b>
                      </i>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        name="budget"
                        value="20-30k"
                        id="budget4"
                      />
                      <i>
                        <b className="withoutHover">$20-30k</b>{" "}
                        <b className="hover">$20-30k</b>
                      </i>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        name="budget"
                        value=">30000"
                        id="budget5"
                      />
                      <i>
                        <b className="withoutHover">{`> $30,000`}</b>{" "}
                        <b className="hover">{`> $30,000`}</b>{" "}
                      </i>
                    </label>
                  </div>

                  <div className="form-group">
                    {/* <button id="submit" type="submit" name="submit"><strong>Submit Now<b></b> <i></i></strong></button> */}
                    <input
                      type="button"
                      value="Submit Now"
                      id="send"
                      onClick={handleSubmit}
                    />
                  </div>
                  <span id="c_error_message" className="text-danger"></span>
                  <span id="c_success_message" className="text-success"></span>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default GetQuote;
