import { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./header";
import LeftSidebar from "./left-sidebar";
import NavigationMenu from "./navigation-menu";

function Layout({ children }) {
  const [openNavigationMenu, setOpenNavigationMenu] = useState(false);

  useEffect(() => {
    if (openNavigationMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [openNavigationMenu]);

  return (
    <div className="app-layout">
      <div className="transition-overlay"></div>
      <NavigationMenu
        openNavigationMenu={openNavigationMenu}
        setOpenNavigationMenu={setOpenNavigationMenu}
      />
      <LeftSidebar />
      <div className="header">
        <Header
          openNavigationMenu={openNavigationMenu}
          setOpenNavigationMenu={setOpenNavigationMenu}
        />
        <>{children}</>
      </div>
      <Footer />
    </div>
  );
}

export default Layout;
