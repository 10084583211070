import React from "react";

function KeyArea() {
  return (
    <section className="featured-services">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="titles">
              <span className="main-title">Key Areas</span>
              <h3>Our Strategy & Execution</h3>
            </div>
          </div>
          <div className="featured-inner work-inner">
            <div className="featured-grid">
              <span>1</span>
              <h6>Discuss</h6>
              <p>
                Define and discuss your project vision with us and we are here
                to turn your project idea into reality.
              </p>
            </div>
            <div className="featured-grid">
              <span>2</span>
              <h6>Design</h6>
              <p>
                The project will be designed by our talented and creative
                designers in a very creative and professional manner.
              </p>
            </div>
            <div className="featured-grid">
              <span>3</span>
              <h6>Build</h6>
              <p>
                Our pros meet standards, develop project to all requirements.
              </p>
            </div>
            <div className="featured-grid">
              <span>4</span>
              <h6>Deploy</h6>
              <p>
                Using latest tech, we deploy and fully support the project to
                ease use, business growth.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default KeyArea;
