import { Link } from "react-router-dom";
import routes from "routes";

function CaseStudy() {
  return (
    <section className="new-case-study text-black">
      <div className="container">
        <div className="row">
          <div className="col-sm-12  mb-4 wow fadeInUp" data-wow-delay=".15s">
            <div className="h-100 p-4 p-sm-3 p-lg-5 related-case-study blue bg-white">
              <div className="row">
                <div className="col-sm-5">
                  {/* <div className="d-flex justify-content-between mb-4">
                    <div>Coming Soon</div>
                    <div>UI / UX / Code </div>
                  </div> */}
                  <h2 className="font-weight-normal">We are</h2>
                  <p className="large">
                    A team of young, highly motivated, and talented developers
                    whose aim is to build real life solutions that can serve the
                    community and contribute to te world of technology.
                  </p>

                  <div className="d-flex">
                    <div className="mt-3">
                      <h3 className="mb-0 font-weight-bold">10+</h3>
                      <p className="grey">Our Team</p>
                    </div>
                  </div>
                  <div className="d-none d-sm-block">
                    <div className="custom-link mt-4 mb-2">
                      <Link
                        to={routes.CONTACT}
                        className=" dribble text-black"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        Contact us
                      </Link>{" "}
                      <span></span> <i></i>
                    </div>
                  </div>
                </div>
                <div className="col-sm-7">
                  <img src="images/team-work.webp" />
                </div>
                <div className="col-sm-12 d-block d-sm-none">
                  <div className="custom-link mt-5 mb-2">
                    <Link
                      to={routes.CONTACT}
                      className="dribble text-black"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      Contact us
                    </Link>{" "}
                    <span></span> <i></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-7 mb-4 wow fadeInUp" data-wow-delay=".35s">
            <div className="h-100 p-4 p-sm-3 p-lg-5 related-case-study blue">
              {/* <div className="d-flex justify-content-between mb-4">
                <div>Crypto</div>
                <div>UI / UX </div>
              </div> */}
              <h2 className="font-weight-normal">Get Solution</h2>
              <p className="large">
                We provide the best TECH-Solutions. Our experts take care of
                design, development, and consulting to make custom software
                solutions for businesses worldwide
              </p>

              {/* <div className="d-flex">
                <div className="mt-3">
                  <h3 className="mb-0 font-weight-bold">10,0000</h3>
                  <p className="grey">Early Signup</p>
                </div>
                <div className="mt-3 pl-5">
                  <h3 className="mb-0 font-weight-bold">1M+</h3>
                  <p className="grey">Followers</p>
                </div>
              </div> */}

              <div className="custom-link mt-4 mb-2">
                <Link
                  to={routes.CONTACT}
                  className="text-black frontier"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  Contact us
                </Link>{" "}
                <span></span> <i></i>
              </div>
            </div>
          </div>
          <div className="col-sm-5 mb-4 wow fadeInUp" data-wow-delay=".5s">
            <div className="h-100 p-4 p-sm-3 p-lg-5 related-case-study yellow">
              {/* <div className="d-flex justify-content-between mb-4">
                <div>API</div>
                <div>UI / UX </div>
              </div> */}
              <h2 className="font-weight-normal">Our Believe</h2>
              <p className="large">
                We believe in delivering the best to our customers in every
                aspect of their requirement by providing them high quality and
                efficient solutions. These values make us reliable and trusted
                for the work.
              </p>

              {/* <div className="d-flex">
                <div className="mt-3">
                  <h3 className="mb-0 font-weight-bold">5,00,000</h3>
                  <p className="grey">Early Signup</p>
                </div>
                <div className="mt-3 pl-5">
                  <h3 className="mb-0 font-weight-bold">1.6M USD</h3>
                  <p className="grey">Funding</p>
                </div>
              </div> */}
              <div className="custom-link mt-4 mb-2">
                <Link to={routes.CONTACT} className="text-black outshifter">
                  Contact us
                </Link>{" "}
                <span></span> <i></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CaseStudy;
