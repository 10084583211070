import { Link } from "react-router-dom";
import { CrossIcon, MenuIcon } from "assets/icons";
import routes from "routes";

function Header({ openNavigationMenu, setOpenNavigationMenu }) {
  const nav = [
    {
      label: "About Us",
      link: "/",
    },
    {
      label: "Services",
      link: routes.SERVICES,
    },
    {
      label: "Contact",
      link: routes.CONTACT,
    },
  ];

  return (
    <nav className="navbar">
      <div className="logo">
        <Link to={routes.HOME}>
          <img src="images/logo-light.png" alt="Image" />
        </Link>
      </div>
      <div className="main-menu">
        <ul>
          {nav.map((item, index) => (
            <li key={index}>
              <Link to={item.link}>{item.label}</Link>
            </li>
          ))}
        </ul>
      </div>
      <div
        className="hamburger-menu d-flex align-items-center"
        id="hamburger-menu"
      >
        <div className="quote-btn">
          <div className="custom-btn white">
            <Link
              to={routes.GET_QUOTE}
              className="px-4 py-2 h-auto mr-2 font-weight-normal"
            >
              Get Quote
            </Link>
          </div>
        </div>
        <div
          className="burger"
          onClick={() => setOpenNavigationMenu(!openNavigationMenu)}
        >
          <div className="burger-icon">
            {openNavigationMenu ? <CrossIcon /> : <MenuIcon />}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;
