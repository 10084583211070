import { BsLaptopFill } from "react-icons/bs";

function ServiceCard({ data }) {
  return (
    <div className="service-card">
      <div className="services-img">{data.icon}</div>
      <figcaption>
        <h4>{data.title}</h4>
        <ul>
          {data.details.map((x, i) => (
            <li key={i}>{x.text}</li>
          ))}
        </ul>
      </figcaption>
    </div>
  );
}

export default ServiceCard;
