import { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Hero from "./hero";
import Layout from "components/Layout";
import CaseStudy from "./caseStudy";
import Services from "./services";
import RecentNews from "./recentNews";
import KeyArea from "./keyArea";
import Awards from "./awards";
import ShowReel from "./showReel";
gsap.registerPlugin(ScrollTrigger);

function Home() {
  // useEffect(() => {
  //   gsap.from(".data-fade", {
  //     ScrollTrigger: {
  //       trigger: ".data-fade",
  //     },
  //     y: 50,
  //     scale: 0.99,
  //     opacity: 0,
  //     delay: 3,
  //     duration: 0.75,
  //     stagger: 0.2,
  //   });
  // }, []);

  return (
    <Layout>
      <Hero />
      <CaseStudy />
      <Services />
      <RecentNews />
      <KeyArea />
      {/* <Awards /> */}
      {/* <ShowReel /> */}
    </Layout>
  );
}

export default Home;
