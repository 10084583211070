import React, { useEffect } from "react";

function Splash() {
  useEffect(() => {
    loader();
  }, []);

  function loader(_success) {
    var obj = document.querySelector(".preloader"),
      inner = document.querySelector(".inner .percentage"),
      page = document.querySelector("body");
    obj.classList.remove("page-loaded");
    page.classList.add("page-loaded");
    var w = 0,
      t = setInterval(function () {
        w = w + 1;
        inner.textContent = w;
        if (w === 100) {
          obj.classList.add("page-loaded");
          page.classList.remove("page-loaded");
          clearInterval(t);
          w = 0;
          if (_success) {
            return _success();
          }
        }
      }, 20);
  }

  return (
    <div className="preloader">
      <div className="inner">
        <figure className="logo">
          <img src="images/logo-light-v.png" alt="Image" />
        </figure>
        <span className="percentage"></span>
      </div>
    </div>
  );
}

export default Splash;
