import Layout from "components/Layout";
import ServiceCard from "components/ServiceCard";
import { servicesList } from "data";

function Services() {
  return (
    <Layout>
      <div className="services-view page-title mb-0">
        <div className="container">
          <div className="page-hdr">
            <h2 className="data-slide-up">Services</h2>
            <h4 className="data-slide-up">
              We use the right tools and methodologies to develop revolutionary
              solutions and quality products!
            </h4>
          </div>
          <div className="services-list">
            {servicesList.map((service, index) => (
              <ServiceCard key={index} data={service} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Services;
