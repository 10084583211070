import React from "react";

function CrossIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      fill="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.226 4.809A1 1 0 0 0 4.81 6.223l5.775 5.774-5.775 5.775a1 1 0 1 0 1.415 1.414L12 13.412l5.775 5.774a1 1 0 0 0 1.414-1.414l-5.774-5.775 5.774-5.774a1 1 0 0 0-1.414-1.414L12 10.583 6.226 4.81Z"></path>
    </svg>
  );
}

export default CrossIcon;
