import routes from "routes";
import { Link } from "react-router-dom";
import Logos from "./logos";

function Hero() {
  return (
    <div className="headlines mt-0 mb-0">
      <div className="container pt-5 pb-0">
        <div className="row">
          <div className="col-sm-12 col-md">
            <h1 data-aos="fade-up" className="data-fade">
              <div>
                Scale Your
                <span>
                  <img src="images/grow-arrow.svg" />
                </span>
              </div>
              Business, <span id="with-us">With Us</span>
            </h1>
            <p className=" mt-4 pt-3 data-fade">
              Powerful apps, websites, designs, and digital products.
              Start-to-end.
            </p>
            <div className="custom-btn white data-fade">
              <Link to={routes.GET_QUOTE} className="dribble">
                Get Quote<span></span> <i></i>
              </Link>
            </div>
          </div>
          <div className="col-sm-12 reviews col-md-auto">
            <div className="mt-5 pt-3">
              <h2 className="font-weight-bold">4.5</h2>
              <p className="text-white small">Satisfied Clients</p>
            </div>
            <div className="mt-5 pt-3">
              <h2 className="font-weight-bold">99+</h2>
              <p className="text-white small">Completed Projects</p>
            </div>
            <div className="mt-5 pt-3">
              <h2 className="font-weight-bold">10+</h2>
              <p className="text-white small">Strong Team</p>
            </div>
          </div>
        </div>
        <div className="company-logos wow fadeInUp">
          <Logos />
        </div>
      </div>
    </div>
  );
}

export default Hero;
